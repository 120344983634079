import {Generic, GenericCollection, JSONLD, Question} from "react-structured-data";
import React from "react";
import renderJsonLd from 'components/ui/faq/faqJsonLdRenderer';

const faq1 = {
    id: 'f1',
    title: 'What is Redis?',
    content: `
    Redis is an open source in-memory key-value store written in C.
    Redis stands for Remote Dictionary Server and is used as a database, cache, queue system, and message broker.
    
    Redis is fast because its data is stored in memory, meaning, unlike traditional databases Redis doesn't need to access the disk.
    While writing this article I learned that Redis is often called a "Data Structure Server" because it provides data types that are similar to those in modern programming languages.
    
    Some data structures that Redis provides are Strings, Lists, Sets, Hashes, and Sorted Sets.
    `
}

const faq2 = {
    id: 'f2',
    title: 'What is Redis used for?',
    content: `
    Even though Redis could be used as your primary Database it's usually not what it is used for.
    Here are the most common use cases for Redis:
    <ul>
        <li>Cache: Redis is used as a cache, which is a fast way to store data in memory.</li>
        <li>Session storage: Redis is used to store session data. Writing and reading data out of Redis is super fast which makes it an ideal candidate for session storage.</li>
        <li>Message queue</li>
    </ul>
    There are a lot of different use cases for Redis, but these are the most common ones.
    `
}

const faq3 = {
    id: 'f3',
    title: 'Where is Redis database stored?',
    content: `
    Redis is storing its data in memory. But depending on your use case Redis can copy the data on your disk.
    This comes obviously handy when you have a large amount of data, and you need to be able to restore it and this is also why you might be needing to back it up.

    Redis regularly dumps its data to an RDB file on the disk based on how snapshots are configured.
    You'll find where the RDB file is saved in redis.config file, under the "Snapshotting" section.
    `
}

const faq4 = {
    id: 'f4',
    title: 'How to back up Redis database?',
    content: `
    To backup your Redis database, you'll need to make a fresh copy of the RDB file.

    Redis offers 2 methods to "force" a snapshot:
    <ul>
        <li>SAVE: This will force a snapshot to be taken synchronously.</li>
        <li>BGSAVE: This will force a snapshot to be taken asynchronously.</li>
    </ul>
    The easiest way is to use the SAVE method but it will block all other operations until the snapshot is done.
    Using BGSAVE will make the server continue to accept commands and will not block other operations but you'll have to figure out when the snapshot is this one is asynchronous.
    `
}

const faq5 = {
    id: 'f5',
    title: 'How to find redis config file?',
    content: `
        The default location of your Redis config file is /etc/redis/redis.config.
        You can also use this command to find the location of your Redis config file: redis-cli config get dir.
    `
}
const structuredData = () => (
  <>{ renderJsonLd([faq1, faq2, faq3, faq4, faq5]) }</>
)

export default structuredData;