import {Generic, GenericCollection, JSONLD, Question} from "react-structured-data";
import React from "react";
import renderJsonLd from 'components/ui/faq/faqJsonLdRenderer';

const faq1 = {
    id: 'f1',
    title: 'When should you use DigitalOcean Backup Solution?',
    content: `
    <ul><li>When a Droplet is used for a single and not sensible project (example: a single website “vitrine” hosted on a Droplet)</li><li>When sensitive technical blocks of your application are already backed up properly and your Droplet is only used for hosting application code (using a remote &amp; backed up database and your data is stored on remote S3 storage)</li></ul>
    `
}

const faq2 = {
    id: 'f2',
    title: 'When you should not use DigitalOcean Backup Solution?',
    content: `
    <ul><li>When your Droplet hosts your database (with heavy I/O operations)<br>→ DigitalOcean discourages using its service in that case as it will be degrading the performance of your backup and application.</li><li>When you need to run your backup at a specific time<br>→ DigitalOcean doesn’t allow you to define your schedule or retention policy</li><li>When you host more than one project on your Droplet/Volume<br>→A DigitalOcean Backup restore will restore your entire Image. You can’t select an individual file/database/project to be restored without impacting everything hosted on that instance.</li><li>When your data is changing often<br>→In this case, you need to be able to have more frequent backup and a more efficient one</li><li>When you work on a project that requires a real backup strategy<br>→ You’ll need control over your backup, combining efficient database backups with files and snapshots while also leveraging external storage</li><li>When your DigitalOcean bill is &gt; $150/m<br>→At that moment, using a service like SimpleBackups will save you money and offer you much more freedom and control</li></ul>
    `
}

const structuredData = () => (
  <>{ renderJsonLd([faq1, faq2]) }</>
)

export default structuredData;