import {Generic, GenericCollection, JSONLD, Question} from "react-structured-data";
import React from "react";
import renderJsonLd from 'components/ui/faq/faqJsonLdRenderer';

const faq1 = {
    id: 'idrive-e2-datacenter',
    title: 'Where are IDrive e2 datacenter located?',
    content: `The company IDrive Inc is based in California, USA.
    IDrive e2 (s3 compatible storage) datacenters are located in the USA and Ireland:
    <ul>
      <li>Chicago</li>
      <li>Dallas</li>
      <li>Ireland</li>
      <li>Los Angeles</li>
      <li>Miami</li>
      <li>Oregon</li>
      <li>Phoenix</li>
      <li>San Jose</li>
      <li>Virginia</li>
    </ul>
    `
}

const structuredData = () => (
  <>{ renderJsonLd([faq1]) }</>
)

export default structuredData;