import React from "react";
import * as Layout from "components/layout";
import { Link, graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import Image from "components/image";
import { Section, Button, Container, Row } from "components/ui";
import { PostTile } from "components/blog";
import { GetStartedSection } from "components/sections";
import tw from "tailwind.macro";
import styled from "styled-components";
import { theme } from "../../tailwind.config";
import "styles/prism-twilight.css";
import "styles/pages/markdown.scss";
import BlogCta from "components/ui/cta/BlogCta";
import Tip from "components/ui/Tip";
import Toc from "components/ui/Toc";
import { Generic, JSONLD } from "react-structured-data";

const shortcodes = { BlogCta, Tip };

const SPostBody = styled.div`
  ${tw`text-text text-lg`}

  @media(max-width: ${theme.extend.screenSizes.md.max}) {
    > blockquote { 
      margin-left: 0px;
    }
  }
`;

const STileContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: -15px;
`;

const renderJsonLd = (slug) => {
  slug = slug.replace(/\//g, "");
  try {
    const BlogJsonLd = require(`../jsonld/${slug}.js`).default;
    return <BlogJsonLd />;
  } catch (err) {
    return <></>;
  }
};

const renderBlogPostJsonLd = (post) => {
  const defaultStructuredData = () => (
    <JSONLD dangerouslyExposeHtml="true">
      <Generic
        type="BlogPosting"
        jsonldtype="BlogPosting"
        schema={{
          headline: post.frontmatter.title,
          editor: post.frontmatter.author,
          publisher: "SimpleBackups",
          datePublished: post.frontmatter.date,
          dateCreated: post.frontmatter.date,
          dateModified: post.frontmatter.lastUpdatedDate,
          description: post.frontmatter.metaDescription,
          author: {
            "@type": "Person",
            name: post.frontmatter.author,
          },
        }}
      />
    </JSONLD>
  );
};

const RelatedPosts = ({ posts }) => {
  return (
    <STileContainer>
      <div className="grid md:grid-cols-3 gap-8">
        {posts.map((post) => {
          return <PostTile post={post}></PostTile>;
        })}
      </div>
    </STileContainer>
  );
};

const authors = {};
authors["Islam"] = (
  <>
    <div className="flex items-center">
      <Image
        className="rounded-full text-center flex align-center justify-center"
        filename="islamessam"
        style={{ maxWidth: "45px", minWidth: "45px" }}
        alt="SimpleBackups founder"
      ></Image>
      <div className="ml-3 md:ml-6 text-base">
        <p className="font-bold">
          Islam Essam
          <a
            href="https://twitter.com/zOxta"
            rel="noreferrer"
            target="_blank"
            className="text-base ml-2"
          >
            <i className="fab fa-twitter text-blue"></i>
          </a>
        </p>
        <p className="opacity-75">Co-founder, SimpleBackups</p>
      </div>
    </div>
  </>
);
authors["Laurent"] = (
  <>
    <div className="flex items-center">
      <Image
        className="rounded-full text-center flex align-center justify-center"
        filename="laurent"
        style={{ maxWidth: "45px", minWidth: "45px" }}
        alt="SimpleBackups founder"
      ></Image>
      <div className="ml-3 md:ml-3 text-sm">
        <p className="font-bold">
          Laurent Lemaire
          <a
            href="https://twitter.com/lem01"
            rel="noreferrer"
            target="_blank"
            className="text-base ml-2"
          >
            <i className="fab fa-twitter text-blue"></i>
          </a>
        </p>
        <p className="opacity-75">Co-founder, SimpleBackups</p>
      </div>
    </div>
  </>
);
authors["Nour"] = (
  <>
    <div className="flex items-center">
      <Image
        className="rounded-full text-center flex align-center justify-center"
        filename="nour"
        style={{ maxWidth: "45px", minWidth: "45px" }}
        alt="SimpleBackups developer"
      ></Image>
      <div className="ml-3 md:ml-3 text-sm">
        <p className="font-bold">Nour Sofanati</p>
        <p className="opacity-75">Developer, SimpleBackups</p>
      </div>
    </div>
  </>
);
authors["Kuba"] = (
  <>
    <div className="flex items-center">
      <Image
        className="rounded-full text-center flex align-center justify-center"
        filename="kuba"
        style={{ maxWidth: "45px", minWidth: "45px" }}
        alt="SimpleBackups Marketing Specialist"
      ></Image>
      <div className="ml-3 md:ml-3 text-sm">
        <p className="font-bold">
          Kuba
          <a
            href="https://twitter.com/contentkuba"
            rel="noreferrer"
            target="_blank"
            className="text-base ml-2"
          >
            <i className="fab fa-twitter text-blue"></i>
          </a>
        </p>
        <p className="opacity-75">Marketing Specialist, SimpleBackups</p>
      </div>
    </div>
  </>
);

export default function Template(props) {
  let {
    data: {
      site: {
        siteMetadata: { url, twitterHandle },
      },
      post,
      relatedPosts,
    },
  } = props;
  console.log(
    "POST",
    props,
  )
  relatedPosts = relatedPosts.edges;

  const author = post.frontmatter.author
    ? authors[post.frontmatter.author]
    : authors["Laurent"];

  const formatDate = (date) => {
    // MMMM, DD YYYY
    return new Date(date).toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  }

  return (
    <Layout.Layout>
      <Layout.SEO
        title={post.frontmatter.metaTitle}
        description={post.frontmatter.metaDescription}
        image={post.frontmatter.thumbnail}
        author={post.frontmatter.author ?? "Laurent"}
        lastUpdateDate={post.frontmatter.lastUpdateDate}
        date={post.frontmatter.date}
        uri={props.uri}
      />
      <Layout.HeroEmpty />
      <Section>
        <Container>
          <Row>
            <div className="flex gap-16 md:px-6">
              <div
                className={
                  "w-full " + (!post.frontmatter.hideToc ? "md:w-3/4" : "")
                }
              >
                <div className="mx-auto">
                  <h1 className="text-left font-heading tracking-tight text-4xl md:text-6xl leading-tight md:leading-snug font-black">
                    {post.frontmatter.title}
                  </h1>

                  <div className="flex items-center mt-6 gap-4 md:gap-12">
                    <div className="">{author}</div>
                    <div className="flex gap-2 items-center border-l border-gray-300 pl-4 md:pl-12 ">
                      <div className="flex items-start gap-4 text-sm items-center">
                        <i className="fad fa-calendar text-gray-400 text-lg"></i>
                        {post.frontmatter.lastUpdateDate && (
                          <div className="font-medium text-left">
                            <div className="flex items-center">
                              <span>
                                Last update on {formatDate(post.frontmatter.lastUpdateDate)}
                              </span>
                            </div>
                            <div className="">
                              <span className="text-gray-400 font-normal">
                                Originally posted on {formatDate(post.frontmatter.date)}
                              </span>
                            </div>
                          </div>
                        )}
                        {!post.frontmatter.lastUpdateDate && (
                          <p className="text-sm font-medium">
                            {formatDate(post.frontmatter.date)}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-12">
                  <div className="pr-4 lg:pr-8">
                    <SPostBody>
                      <div className="markdown-body">
                        <MDXProvider components={shortcodes}>
                          <MDXRenderer>{post.body}</MDXRenderer>
                        </MDXProvider>
                      </div>
                      <br />
                      <hr className="my-6" />
                      <Link to="/blog/">
                        <Button size="medium" color="primary">
                          Back to blog
                        </Button>
                      </Link>
                    </SPostBody>
                  </div>
                </div>
              </div>
              {!post.frontmatter.hideToc && (
                <div className="hidden md:block md:w-1/4">
                  <Toc
                    title={post.frontmatter.title}
                    post={post.tableOfContents}
                  ></Toc>
                </div>
              )}
            </div>
          </Row>
        </Container>
      </Section>
      {relatedPosts.length > 0 && (
        <Section color="alt">
          <Container>
            <Row>
              <div className="col-md-12">
                <h3 className="text-4xl font-bold pb-6">Related Articles</h3>
                <RelatedPosts posts={relatedPosts} />
              </div>
            </Row>
          </Container>
        </Section>
      )}
      <GetStartedSection />
      {renderJsonLd(post.fields.slug)}
    </Layout.Layout>
  );
}

export const MdxFrontmatterSchemaExtend = `
  hideToc: Boolean
`;

export const postQuery = graphql`
  query BlogPostBySlug($id: String, $relatedPosts: [String]) {
    site: site {
      siteMetadata {
        url
        twitterHandle
      }
    }
    post: mdx(id: { eq: $id }) {
      frontmatter {
        title
        author
        layout
        date
        thumbnail
        rating
        metaDescription
        metaTitle
        lastUpdateDate
        hideToc
        tags
      }
      fields {
        slug
      }
      tableOfContents
      body
    }
    relatedPosts: allMdx(
      limit: 3
      filter: { slug: { in: $relatedPosts } }
    ) {
      edges {
        node {
          id
          excerpt
          frontmatter {
            title
            thumbnail
            date(formatString: "DD-MM-YYYY")
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;